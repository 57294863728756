import React, { useState } from "react"
import { Classes, Icon } from "@blueprintjs/core";

const GroupButton = ({group, color, icon = "lab-test", svg}) => {

  const [isHovered, setIsHovered] = useState(false);

  const buttonStyle = { 
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#BE0000",
    backgroundColor: "white",
    textAlign: "center",
    fontSize: "1rem",
    fontFamily: "Montserrat, sans-serif",
    cursor : "default", 
    width: "250px", 
    borderRadius: "3px", 
    border: `2px solid #BE0000`,
    gap: 10,
    fontWeight: 500,
    textTransform: "uppercase",
  }

  const hoveredStyle = {
    ... buttonStyle,
    color: "white",
    backgroundColor: color,
    textDecoration: "none",
    border: `2px solid ${color}`,
  }

  let DangerIcon = ({ content }) => { return <span aria-hidden="true">
    <div style={{ width: 30, height: 30, overflow: "hidden" }} dangerouslySetInnerHTML={{ __html: content }}/>
  </span> }

  let groupIcon = <Icon icon={icon} color={(isHovered ? "white" : color)} size={32}/>
  if(group.icon !== undefined && group.icon !== null && `${group.icon}`.length > 0) { groupIcon = <DangerIcon svg={group.icon}/> }
  if(group.icon !== undefined && group.icon !== null && `${group.icon}`.startsWith("icon:")) {  groupIcon = <Icon icon={`${group.icon}`.slice(5)} color={(isHovered? "white" : color)} size={32}/> }

  return (<a className={Classes.CALLOUT} style={(isHovered ? hoveredStyle : buttonStyle)} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} href={"#/group/"+ group.id}> {groupIcon} {group.name} </a> );
}

export default GroupButton;
