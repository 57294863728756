
import { Classes, Button, InputGroup, FormGroup, TextArea, NumericInput, RadioGroup, Radio, Checkbox, Tag, HTMLSelect } from "@blueprintjs/core";
import { DateInput3 as DateInput } from "@blueprintjs/datetime2"
import MarkdownIt from "markdown-it";
let markdown = new MarkdownIt({
    html: true
});
import moment from "moment"
import isEmail from "validator/lib/isEmail";

let generateHelperText = (field) => {
    let helper_text = (field.helper_text ? field.helper_text : "")
    let min = (field.min ? field.min : "Any");
    let max = (field.max ? field.max : "Any");
    if (field.type == "number") { return `${helper_text} [${min} to ${max}]` }
    return `${helper_text}`
}

let EntryDetailField = ({field, getFieldValue, setFieldValue, optionIsChecked, setCheckedOption, resendSignatureRequest, tags}) => {

    switch (field.type) {
        case "markdown_block": {
            let block_content = field.markdown_block;
            if (typeof block_content == "undefined") { block_content = ""; }
            if (block_content.length < 1 || block_content == null) { block_content = ""; }
            if (typeof block_content !== "string") { block_content = "Configuration Error : Block Content Type - " + typeof block_content; }
            let html_content = markdown.render(block_content);
            return (<div style={{ flexBasis: "100%" }}>
                <FormGroup label={<b>{field.label}</b>} helperText={generateHelperText(field)}>
                    <div dangerouslySetInnerHTML={{ __html: html_content }}></div>
                </FormGroup>
            </div>)
        } break;
        case "text_field": {
            let field_status = {};
            let currentValue = getFieldValue(field)
            let statusMessage = "";
            if (`${currentValue}`.length < 1) { field_status.color = "red"; }
            if ((field.max > 0) && `${currentValue}`.length > field.max) { field_status.color = "red"; statusMessage = " - Too Long";}
            if (`${currentValue}`.length < (field.min || 0)) { field_status.color = "red"; statusMessage = " - Too Short";}
            let required_element = (<span style={field_status}>[Required{statusMessage}]</span>)
            return (<div style={{ flexBasis: "31%", paddingRight: "2%" }}>
                <FormGroup label={<b>{field.label}</b>} helperText={generateHelperText(field)} labelInfo={(field.required === "yes" ? required_element : "")}>
                    <InputGroup type="text" value={getFieldValue(field)} onChange={setFieldValue.bind(null, field)} />
                </FormGroup>
            </div>)
        } break;
        case "signature_block": {
            let signature_status = (() => {
                if (typeof tags == "undefined") {
                    return (<span></span>);
                }
                let is_signed = tags.reduce((acc, t) => {
                    let tag_expression = new RegExp(`.*${field.key}:signed:.*`);
                    if (tag_expression.test(t)) {
                        let tag_components = t.split(":");
                        let datestamp = moment(tag_components.slice(2).join(":"), "YYYY-MM-DDTHH:mm:ssZ")
                        return datestamp.format("YYYY-MM-DD HH:mm:ss");
                    }
                    return acc;
                }, false)
                if (is_signed) {
                    return (<Tag round={true} intent="primary"> Signed : {is_signed} </Tag>)
                }
                return (<Tag round={true} intent="warning"> Signature Pending </Tag>)
            })()
            
            let signature_resend_button = <span></span>
            if (typeof resendSignatureRequest == "function") {
                signature_resend_button = <Button icon="refresh" style={{ display: "inline-block", marginTop: 5 }} text="Resend Signature Request" onClick={resendSignatureRequest.bind(null, field)} />
            }

            let field_status = {};
            let currentValue = getFieldValue(field)
            if( !isEmail(`${currentValue}`) ) { field_status.color = "red"; }
            if (`${currentValue}`.length < 1) { field_status.color = "red"; }
            let required_element = (<span style={field_status}>[Required]</span>)

            return (<div style={{ flexBasis: "100%" }}>
                <FormGroup label={<b>{field.label}</b>} helperText={"Enter the email of the person who's signature is required"} labelInfo={required_element}>
                    <InputGroup type="text" rightElement={signature_status} value={getFieldValue(field)} onChange={setFieldValue.bind(null, field)} />
                    {signature_resend_button}
                </FormGroup>
            </div>)
        } break;
        case "text_area": {
            let field_status = {};
            let currentValue = getFieldValue(field)
            let statusMessage = "";
            if (`${currentValue}`.length < 1) { field_status.color = "red"; }
            if ((field.max > 0) && `${currentValue}`.length > field.max) { field_status.color = "red"; statusMessage = " - Too Long";}
            if (`${currentValue}`.length < (field.min || 0)) { field_status.color = "red"; statusMessage = " - Too Short";}
            let required_element = (<span style={field_status}>[Required{statusMessage}]</span>)
            return (<div style={{ flexBasis: "100%" }}>
                <FormGroup label={<b>{field.label}</b>} helperText={generateHelperText(field)} labelInfo={(field.required === "yes" ? required_element : "")}>
                    <TextArea growVertically={true} style={{ width: "100%"}} value={getFieldValue(field)} onChange={setFieldValue.bind(null, field)} />
                </FormGroup>
            </div>)
        } break;
        case "number": {
            let field_status = {};
            let currentValue = getFieldValue(field)
            let statusMessage = "";
            if(currentValue == undefined || currentValue == "") { statusMessage = " - No Value Set"; field_status.color = "red"; }
            let required_element = (<span style={field_status}>[Required{statusMessage}]</span>)
            return (<div style={{ flexBasis: "31%", paddingRight: "2%" }}>
                <FormGroup label={<b>{<b>{field.label}</b>}</b>} helperText={generateHelperText(field)} labelInfo={(field.required === "yes" ? required_element : "")}>
                    <NumericInput allowNumericCharactersOnly={true} max={field.max} min={field.min} value={getFieldValue(field)} onValueChange={(val, str) => {
                        if(str.length == 0) { setFieldValue(field, { target: { value: "" } }); return; } 
                        if(str.startsWith("-")) { setFieldValue(field, { target: { value: str } }); return; }
                        if(str.endsWith(".")) { setFieldValue(field, { target: { value: str } }); return; }
                        setFieldValue(field, { target: { value: val } }) 
                    }} />
                </FormGroup>
            </div>)
        } break;
        case "date-picker": {
            return (<div style={{ flexBasis: "31%", paddingRight: "2%" }}>
                <FormGroup label={<b>{<b>{field.label}</b>}</b>} helperText={generateHelperText(field)} labelInfo={(field.required === "yes" ? "(Required)" : "")}>
                    <DateInput 
                        parseDate={(v) => { return moment(v).toDate() }}
                        formatDate={(d) => { return moment(d).format("YYYY-MM-DD") }}
                        value={getFieldValue(field)} 
                        onChange={(d) => { setFieldValue(field, { target: { value: moment(d).format("YYYY-MM-DD") }})}}/>
                </FormGroup>
            </div>)
        } break;
        case "duration": {
            var components = `${getFieldValue(field)}`.split(":")
            if (components[0] == undefined || isNaN(parseInt(components[0], 10)) ) { components[0] = 0; }
            if (components[1] == undefined || isNaN(parseInt(components[1], 10))) { components[1] = 0; }
            if (components[2] == undefined || isNaN(parseInt(components[2], 10))) { components[2] = 0; }
            let updateValue = (position, event) => {
                components[position] = (isNaN(parseInt(event.target.value, 10)) ? 0 : parseInt(event.target.value, 10));

                // Enforce Num on all components
                components[0] = (isNaN(parseInt(components[0], 10)) ? 0 : parseInt(components[0], 10));
                components[1] = (isNaN(parseInt(components[1], 10)) ? 0 : parseInt(components[1], 10));
                components[2] = (isNaN(parseInt(components[2], 10)) ? 0 : parseInt(components[2], 10));

                // Normalize Seconds Component
                components[1] = components[1] + (Math.floor(components[2] / 60))
                components[2] = components[2] % 60

                // Normalize Minutes Component
                components[0] = components[0] + (Math.floor(components[1] / 60))
                components[1] = components[1] % 60

                setFieldValue(field, { target: { value: components.join(":") }})
            }
            return (<div style={{ flexBasis: "31%", paddingRight: "2%" }}>
                <FormGroup label={<b>{<b>{field.label}</b>}</b>} helperText={generateHelperText(field)} labelInfo={(field.required === "yes" ? "(Required)" : "")}>
                    <div style={{ display: "flex" }}>
                        <InputGroup type="text" size={3} value={components[0]} onChange={updateValue.bind(null, 0)}/><Button minimal={true}>Hours</Button>
                        <InputGroup type="text" size={3} value={components[1]} onChange={updateValue.bind(null, 1)}/><Button minimal={true}>Minutes</Button>
                        <InputGroup type="text" size={3} value={components[2]} onChange={updateValue.bind(null, 2)}/> <Button minimal={true}>Seconds</Button>
                    </div>
                </FormGroup>
            </div>)
        } break;
        case "select": {
            let options = [];
            if (Array.isArray(field.options)) { options = field.options; }
            let selectElements = options.map((option, index) => {
                return <Radio key={`form_option_${index}`} label={option.label} value={option.value} />
            });
            let field_status = {};
            let currentValue = getFieldValue(field)
            if (`${currentValue}`.length < 1) { field_status.color = "red"; }
            let required_element = (<span style={field_status}>[Required]</span>)
            return (<div style={{ flexBasis: "48%", paddingRight: "2%" }}>
                <FormGroup label={<b>{field.label}</b>} helperText={generateHelperText(field)} labelInfo={(field.required === "yes" ? required_element : "")}>
                    <RadioGroup selectedValue={getFieldValue(field)} onChange={setFieldValue.bind(null, field)}>
                        {selectElements}
                    </RadioGroup>
                </FormGroup>
            </div>)
        } break;
        case "dropdown": {
            let options = [];
            if (Array.isArray(field.options)) { options = field.options; }
            let selectElements = options.map((option, index) => {
                return <option key={`form_option_${index}`} value={option.value}>{option.label}</option>
            });
            selectElements.unshift(<option key={`form_option_initial`} disabled={true} value={""}>-- Please Select A Value --</option>)
            let field_status = {};
            let currentValue = getFieldValue(field)
            if (`${currentValue}`.length < 1) { field_status.color = "red"; }
            let required_element = (<span style={field_status}>[Required]</span>)
            return (<div style={{ flexBasis: "48%", paddingRight: "2%" }}>
                <FormGroup label={<b>{field.label}</b>} helperText={generateHelperText(field)} labelInfo={(field.required === "yes" ? required_element : "")}>
                    <HTMLSelect value={getFieldValue(field)} onChange={setFieldValue.bind(null, field)}>
                        {selectElements}
                    </HTMLSelect>
                </FormGroup>
            </div>)
        } break;
        case "multi_select": {
            let options = [];
            if (Array.isArray(field.options)) { options = field.options; }
            let field_status = {};
            let currentValue = getFieldValue(field)
            if (`${currentValue}`.length < 1) { field_status.color = "red"; }
            let required_element = (<span style={field_status}>[Required]</span>)
            let selectElements = options.map((option, index) => {
                return <Checkbox key={`form_option_${index}`} checked={optionIsChecked(field, option)} label={option.label} onChange={setCheckedOption.bind(null, field, option)} />
            });
            return (<div style={{ flexBasis: "48%", paddingRight: "2%"}}>
                <FormGroup label={<b>{field.label}</b>} helperText={generateHelperText(field)} labelInfo={(field.required === "yes" ? required_element : "")}>
                    {selectElements}
                </FormGroup>
            </div>)
        } break;
        case "section_divider": { return (<div style={{ flexBasis: "100%" }}> <h3 className={Classes.HEADING}>{field.label}</h3><hr /></div>) } break;
        default: { return (<div>  Unknown Field Type {field.type} </div>) } break;
    }
}

export default EntryDetailField