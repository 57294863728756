
	'use strict';

	/*

		Group Permissions Event Bus

	*/

	import moment from "moment";
  import AppData from "data-modules/AppData";
	import Eventer from "../Eventer";
  import { get, post, put, patch, del, getErrorPayload  } from "data-modules/SystemREST";
  import queryString from 'query-string';
  var GroupPermissionData = Eventer.createBus("GRPPERM");
  // AppData.injectJWTErrorListener(GroupPermissionData);

  var _dataCache = {};
  var _lastCached = null;

	GroupPermissionData.getRecordList = function (user_id) {
		if(user_id == "undefined" || user_id == "") { GroupPermissionData.notifyListeners("LIST_LOADED", []); return; }
    return get("/user/"+user_id+"/group/list").send().then(function(res) {
      var payload = res.body;
      _dataCache.permissions = payload.permissions;
      if(user_id == AppData.get("userID")) { AppData.set("groupPermissions", payload.permissions); } // Update Auth Permissions Cache if Loaded Permissions are for Logged In User
      _lastCached = moment();
      GroupPermissionData.notifyListeners("LIST_LOADED", payload.permissions);
      return payload.permissions;
    }, function (err) {
      GroupPermissionData.notifyListeners("LIST_LOAD_ERROR", getErrorPayload(err));
    });

  };

	GroupPermissionData.updateRecord = function (user_id, permission) {
    post("/user/"+user_id+"/group/update").send({permission : permission}).then(function(res) {
      var payload = res.body;
      delete _dataCache.permissions;
      GroupPermissionData.notifyListeners("RECORD_UPDATED", payload.group);
    }, function (err) {
      GroupPermissionData.notifyListeners("RECORD_UPDATE_ERROR", getErrorPayload(err));
    });

  };

	GroupPermissionData.createRecord = function (user_id, recordData) {
    post("/user/"+user_id+"/group/add").send({user_id : recordData.user_id, group_id : recordData.group_id}).then(function(res) {
      var payload = res.body;
      delete _dataCache.permissions;
      GroupPermissionData.notifyListeners("RECORD_CREATED", payload.group);
    }, function (err) {
      GroupPermissionData.notifyListeners("RECORD_CREATE_ERROR", getErrorPayload(err));
    });
  };

	GroupPermissionData.removeRecord = function (user_id, permission_id) {
    post("/user/"+user_id+"/group/remove").send({permission_id : permission_id}).then(function(res) {
      var payload = res.body;
      GroupPermissionData.notifyListeners("RECORD_REMOVED", payload.group);
    }, function (err) {
      GroupPermissionData.notifyListeners("RECORD_REMOVE_ERROR", getErrorPayload(err));
    });

  };

  // Specific Record Update Alias -- Used by UI Element
	GroupPermissionData.updatePermissionType = function (user_id, newType, permission_id) {
		GroupPermissionData.updateRecord(user_id, {id : permission_id, type : newType});
  };
  
  GroupPermissionData.myGroups = function () {
    let user_id = AppData.get("userID")
    return GroupPermissionData.getRecordList(user_id)
  }
  
  // Group Level Permission Management Actions

  GroupPermissionData.loadGroupPermissions = function (group_id, opts) {

    let options = opts || {};
    let queryOptions = {};

    // Apply pagination if exists
    if(options._page && options._pageSize) {
      queryOptions._pageSize = options._pageSize;
      queryOptions._page = options._page;
    }

    // Apply query options if it exists
    if(options.where) { queryOptions = Object.assign(queryOptions, options.where) }

    let requestURL = "/group/" + group_id + "/permissions";
    if(queryString.stringify(queryOptions).length > 0) { requestURL = `${requestURL}?${queryString.stringify(queryOptions)}`}
    return get(requestURL).send().then(function (res) {
      var payload = res.body;
      GroupPermissionData.notifyListeners("PERMISSIONS_LIST_LOADED", { permissions: payload.records, count: payload.count });
      return { permissions: payload.records, count: payload.count }
    }, function (err) {
      GroupPermissionData.notifyListeners("PERMISSIONS_LIST_LOAD_ERROR", getErrorPayload(err));
    });

  }

  GroupPermissionData.updateGroupPermission = function (group_id, permission_id, user_id, value) {
    post("/group/" + group_id + "/permission").send({
      id: permission_id,
      user_id: user_id,
      value: value
    }).then(function (res) {
      var payload = res.body;
      GroupPermissionData.notifyListeners("PERMISSION_UPDATED", payload);
    }, function (err) {
      GroupPermissionData.notifyListeners("PERMISSION_UPDATE_ERROR", getErrorPayload(err));
    });
  }

  export default GroupPermissionData;
