import { useState, useEffect, useCallback } from "react"
import { Button, HTMLSelect, FormGroup, InputGroup, ControlGroup, Tag, Spinner } from "@blueprintjs/core";
import { DateInput3 as DateInput, DatePicker3 as DatePicker } from "@blueprintjs/datetime2";
import UserAccountPicker from "generic-components/UserAccountPicker";
import EventData from "data-modules/EventData";
import ServiceRateData from "data-modules/ServiceRateData";
import ServiceData from "data-modules/ServiceData";
import AppData from "data-modules/AppData";
import UserData from "data-modules/UserData";
import { DateTime } from "luxon";
import moment from "moment-timezone";

export default function BatchEventWizard({ serviceID }) {
    
    let now = DateTime.now();

    let [startDate, setStartDate] = useState(DateTime.now().toISO());
    
    let [period, setPeriod] = useState(7);
    let [duration, setDuration] = useState(1);
    let [sequenceCount, setSequenceCount] = useState(4);
    let [eventConfigs, setEventConfigs] = useState([]);
    let [collisionsChecked, setCollisionsChecked] = useState(false);

    let [availableRates, setAvailableRates] = useState([]);
    let [selectedRate, setSelectedRate] = useState({ id: -1 });

    let [pendingUser, setPendingUser] = useState(null);
    let [pendingAccount, setPendingAccount] = useState(null);

    let [activeService, setActiveService] = useState(null);
    let [isLoading, setIsLoading] = useState(true);

    let [eventInFlightStatus, setEventInFlightStatus] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        ServiceRateData.getRecordList(serviceID).then((rates) => { 
            setAvailableRates(rates); 
            setSelectedRate(rates[0]);
        });
        UserData.getRecord(AppData.get("userID")).then((user) => { setPendingUser(user) });
        ServiceData.getRecord(serviceID).then((service) => { 
            setActiveService(service); 
            setStartDate(DateTime.now().minus({ minute: (now.minute % (service.interval < 60 ? service.interval : 60) )}).toISO());
            setDuration((service.interval / 60));
            setIsLoading(false);
        });
    }, [serviceID])

    const getCollisions = () => {        
        EventData.checkEventBatch({ 
            service_id: serviceID, 
            owner_id: pendingUser.id,
            account_id: pendingAccount.id, 
            start_date: startDate, 
            duration: duration, 
            count: sequenceCount,
            period: period
        }).then((evtCfgs) => {
            setEventConfigs(evtCfgs);
            setCollisionsChecked(true);
            setEventInFlightStatus(evtCfgs.map(() => false))
        })
    }

    let userAccountConfig = {
        allow_user_select: true,
        allow_account_select: true
    }

    let userAccountHandlers = {
        onUserSelected: (user, isValid) => {
            setPendingUser(user);
            setPendingAccount(null);
            resetBatchChecks();
        },
        onAccountSelected: (account, isValid) => {
            setPendingAccount(account);
            resetBatchChecks();
        }
    }

    let ratesList = availableRates.map((rate) => {
        let labelString = rate.label
        let disabled = false;
        return { label: labelString, value: rate.id, disabled: disabled };
    });

    const resetBatchChecks = () => {
        setCollisionsChecked(false);
        setEventConfigs([])
        setEventInFlightStatus([])
    }

    const createEvent = (config, config_index) => {

        let eisa = [...eventInFlightStatus];
        eisa[config_index] = true;
        setEventInFlightStatus(eisa);
        
        return EventData.createRecord({
            id: "new",
            service_id: config.service_id,
            start_date: DateTime.fromISO(config.start_date).toJSDate(),
            duration: config.duration,
            end_date: DateTime.fromISO(config.end_date).toJSDate(),
            user_id: config.user_id,
            account_id: config.account_id,
            rate_id: selectedRate.id
        }).then((event) => {
            let newStatus = [...eventConfigs];
            newStatus[config_index].event_id = event.id;
            setEventConfigs(newStatus);

            let eisa = [...eventInFlightStatus];
            eisa[config_index] = false;
            setEventInFlightStatus(eisa);

            return config_index;
        }).catch((err) => {
            let eisa = [...eventInFlightStatus];
            eisa[config_index] = false;
            setEventInFlightStatus(eisa);
        })
    } 

    const createAll = () => {
        let events = eventConfigs.reduce((evtAc, config, index) => { 
            if(config.collisions.length > 0) { return evtAc } 
            return createEvent(config.event_config, index) 
        }, []);

        if(events.length === 0) { return alert("No Valid Configs - Skipped All"); }
        if(events.length < eventConfigs.length) { alert(`Skipping ${eventConfigs.length - events.length} invalid events`); }


        const runActions = () => {
            return events.reduce((prev, task) => {
                return prev.then(task).catch((err) => {})
            }, Promise.resolve());
        }

        runActions();
    }

    const incrementStart = () => { setStartDate(DateTime.fromISO(startDate).plus({ minutes: activeService.interval }).toISO()); }
    const decrementStart = () => { setStartDate(DateTime.fromISO(startDate).minus({ minutes: activeService.interval }).toISO()); }

    if(isLoading) { return <div>Loading...</div> }

    return (<div> 
        Batch Event Wizard ({serviceID}) 
        <UserAccountPicker user={pendingUser} account={pendingAccount} handlers={userAccountHandlers} config={userAccountConfig} comparisonDate={moment(startDate)}/>
        <FormGroup label="Start Date">
            <ControlGroup>
                <Button icon={"less-than"} onClick={decrementStart}></Button>
                <DateInput 
                    value={startDate} 
                    showTimezoneSelect={false}
                    dateFnsFormat="yyyy-MM-dd HH:mm"
                    onChange={(value) => { setStartDate(value); resetBatchChecks(); }}
                />
                <Button icon={"greater-than"} onClick={incrementStart}></Button>
            </ControlGroup>
        </FormGroup>
        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            <FormGroup label="Event Duration (Hrs)">
                <ControlGroup>
                    <InputGroup type="number" style={{ width: 100 }} min={0.25} value={duration} onChange={(e) => { setDuration(parseFloat(e.target.value)); resetBatchChecks(); }} />
                    <HTMLSelect onChange={(e) => { setDuration(parseFloat(e.target.value)); resetBatchChecks(); }}>
                        <option disabled> --- Presets --- </option>
                        <option value={0.25}> 15 Minutes </option>
                        <option value={0.5}> 30 Minutes </option>
                        <option value={1}> 1 Hour </option>
                        <option value={2}> 2 Hours </option>
                    </HTMLSelect>
                </ControlGroup>
            </FormGroup>
            <FormGroup label="Sequence Config">
                <ControlGroup>
                    <HTMLSelect value={period} onChange={(e) => setPeriod(e.target.value)}>
                        <option value={1}> Daily </option>
                        <option value={7}> Weekly </option>
                    </HTMLSelect>
                    <InputGroup min={1} style={{ width: 75 }} type="number" leftIcon="numerical" value={sequenceCount} onChange={(e) => { setSequenceCount(parseInt(e.target.value)); resetBatchChecks(); }} />
                    <HTMLSelect onChange={(e) => { setSequenceCount(parseInt(e.target.value)); resetBatchChecks(); }}>
                        <option disabled> --- Presets --- </option>
                        <option value={4}> 1 Month (Weekly - 4) </option>
                        <option value={12}> 3 Months (Weekly - 12) </option>
                        <option value={26}> 6 Months (Weekly - 26) </option>
                        <option value={52}> 12 Months (Weekly - 52) </option>
                    </HTMLSelect>
                </ControlGroup>
            </FormGroup>
        </div>
        <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
            <FormGroup label="Terminates on">
                {(startDate !== null ? DateTime.fromISO(startDate).plus({ days: period * (sequenceCount - 1) }).toLocaleString() : "No Date Selected")}
            </FormGroup>
        </div>
        <FormGroup
            label="Process"
            labelInfo="(required)">
            <HTMLSelect options={ratesList} value={selectedRate.id} onChange={(event) => { setSelectedRate(availableRates.find(r => r.id == event.target.value)) }} />
        </FormGroup>
        {(collisionsChecked && <FormGroup label="Event Configuration">
            <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                {eventConfigs.map((c, i) => {

                    let buttonIntent = "primary";
                    if(c.collisions.length > 0) { buttonIntent = "danger"; }
                    
                    let buttonLabel = "Create Event";
                    if(c.collisions.length > 0) { buttonLabel = "Has Collisions"; }

                    if(c.event_id !== undefined) { buttonIntent = "success"; buttonLabel = "Event Created"; }

                    return <div key={`pec_${i}`} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 5, background: "lightgray", borderRadius: 5 }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <span>From: {DateTime.fromISO(c.event_config.start_date).toLocaleString({ ...DateTime.DATETIME_MED, weekday: "short" })}</span>
                            <span>To: {DateTime.fromISO(c.event_config.end_date).toLocaleString({ ...DateTime.DATETIME_MED, weekday: "short" })}
                            </span>
                        </div>
                        {eventInFlightStatus[i] === true ? <Spinner/> : <Button intent={buttonIntent} disabled={(c.collisions.length > 0 || buttonIntent == "success")} onClick={() => { createEvent(c.event_config, i); }}>{buttonLabel}</Button>}
                    </div>
                })}
            </div>
        </FormGroup>)}
        <div>
            <ControlGroup>
                <Button intent="none" disabled={!pendingUser || !pendingAccount} onClick={getCollisions}> Check Collisions </Button>
                <Button intent="primary" disabled={(eventConfigs.length < 1)} onClick={createAll}> Create All Pending </Button>
            </ControlGroup>
        </div>
        <div>
            
        </div>
        
    </div>)
}