
import React from 'react';
import { Button, FormGroup, HTMLSelect, InputGroup, ControlGroup, TextArea, Callout, AnchorButton, ProgressBar, Tag, Tooltip } from "@blueprintjs/core";
import { DateInput3 as DateInput } from "@blueprintjs/datetime2"
import UserAccountPicker from "generic-components/UserAccountPicker";
import RequestedEventUserNotifications from "./eventRequestedUserNotifications";
import EventUserNotifications from "./eventUserNotifications";
import AppData from "data-modules/AppData"
import EventData from "data-modules/EventData"
import ServiceData from "data-modules/ServiceData"
import SiteDialogData from "data-modules/SiteDialogData"
import UserData from "data-modules/UserData"
import moment from "moment-timezone";
import InterlockControls from "./interlockControls";
import MarkdownIt from "markdown-it";
import DurationField from "./DurationField"
import OrderLineItemManager from "./OrderLineItemManager";
import { areRequiredDataFieldsValid, DataInputField } from "generic-components/DataInputField";
let markdown = new MarkdownIt({
    html: true
});

import { service_types } from "data-modules/Enum"

export default class EventDetailForm extends React.Component {
    constructor(props) {
        super(props)
        
        this.componentDidMount = this.componentDidMount.bind(this);
        this.updateResource = this.updateResource.bind(this);
        this.updateRate = this.updateRate.bind(this);
        this.incrementStart = this.incrementStart.bind(this);
        this.decrementStart = this.decrementStart.bind(this);
        this.incrementDuration = this.incrementDuration.bind(this);
        this.decrementDuration = this.decrementDuration.bind(this);
        this.setDuration = this.setDuration.bind(this);
        this.submitEvent = this.submitEvent.bind(this);
        this.cancelEvent = this.cancelEvent.bind(this);
        this.deleteEvent = this.deleteEvent.bind(this);
        this.checkCollision = this.checkCollision.bind(this);
        this.updateStatus = this.updateStatus.bind(this);
        this.updatePONumber = this.updatePONumber.bind(this);
        this.updateDescription = this.updateDescription.bind(this);
        this.durationOnWheel = this.durationOnWheel.bind(this);
        this.startDateOnWheel = this.startDateOnWheel.bind(this);
        this.requestNotifcation = this.requestNotifcation.bind(this);
        this.removeNotificationRequest = this.removeNotificationRequest.bind(this);

        // Interlock Functions
        this.postLock = this.postLock.bind(this);
        this.postUnlock = this.postUnlock.bind(this);

        this.state = {};

        if(typeof props.clickParams.date !== "undefined") {
            
            let selectedResource = props.resources.reduce((a, r) => {
                if (r.id == props.clickParams.resource.id) { return r; }
                return a;
            }, props.resources[0])

            // Set Start Date to beginning of current slot duration (eg if start is 15m and duration is 30 min set start to nearest preceeding hour or half hour)
            let start_date = moment(props.clickParams.date)
            const minutesSinceDayStart = (start_date.hours() * 60) + start_date.minute()
            let slotOffset = minutesSinceDayStart % (selectedResource.duration > 60 ? 60 : selectedResource.duration);
            start_date.subtract(slotOffset, "minutes")
        

            this.state = {
                mode: "create",
                revertDate: moment(props.clickParams.date),
                isValid: true,
                allowUserScheduleInLeadTime: false,
                selectedResource: selectedResource,
                createdAt: moment(),
                start_date: start_date,
                duration: selectedResource.duration,
                selectedAccount: props.clickParams.defaultAccount,
                selectedUser: props.clickParams.defaultUser,
                activeRate: props.clickParams.serviceRates[0],
                order_billing_status: 1,
                po_number: "",
                description: "",
                data_1: null,
                data_2: null,
                data_3: null,
                data_4: null,
                data_5: null,
                collisions: [],
                notifications: [],
                error: false,
                isCheckingCollision: false,
            }
        }

        if (typeof props.clickParams.event !== "undefined") {
            const eventData = props.clickParams.eventData;
            eventData.service.duration = eventData.service.interval;

            let selectedRate = props.clickParams.serviceRates.reduce((a, r) => {
                if (r.id == eventData.rate_id ) { return r; }
                return a;
            }, props.clickParams.serviceRates[0])
            let selectedResource = {
                title: eventData.service.title,
                id: eventData.service.id,
                group: eventData.service.group_id,
                service: eventData.service,
                duration: eventData.service.interval
            }
            this.state = {
                mode: "edit",
                revertDate: moment(eventData.start_date),
                isValid: true,
                allowUserScheduleInLeadTime: false,
                selectedResource: selectedResource,
                createdAt: eventData.createdAt,
                start_date: eventData.start_date,
                duration: eventData.total_duration * 60,
                selectedAccount: eventData.order.account,
                selectedUser: eventData.order.user,
                activeRate: selectedRate,
                po_number: eventData.order.po_number,
                description: eventData.description,
                order_billing_status: eventData.order.order_billing_status,
                billing_exclusion_justification: eventData.order.billing_exclusion_justification,
                data_1: eventData.data_1,
                data_2: eventData.data_2,
                data_3: eventData.data_3,
                data_4: eventData.data_4,
                data_5: eventData.data_5,
                collisions: [],
                error: false
            }

        }
    }

    componentDidMount() {
        if (typeof this.props.clickParams.date !== "undefined") {

            let selectedResource = this.props.resources.reduce((a, r) => {
                if (r.id == this.props.clickParams.resource.id) { return r; }
                return a;
            }, this.props.resources[0])

            let start_date = moment(this.props.clickParams.date)
            const minutesSinceDayStart = (start_date.hours() * 60) + start_date.minute()
            let slotOffset = minutesSinceDayStart % (selectedResource.duration > 60 ? 60 : selectedResource.duration);
            start_date.subtract(slotOffset, "minutes")

            this.checkCollision({
                start_date: start_date,
                duration: selectedResource.duration,
            });
        }

        if (typeof this.props.clickParams.event !== "undefined") {

            const eventData = this.props.clickParams.eventData;

            this.checkCollision({
                start_date: eventData.start_date,
                duration: eventData.total_duration * 60,
            });
        }

        UserData.hasServiceTag(AppData.get("userID"), this.state.selectedResource.id, "allow_schedule_in_lead", "create").then((result) => { this.setState({ allowUserScheduleInLeadTime: result.hasTag }) })
    }

    checkCollision(state_merge) {
        
        let isManager = AppData.authorize(20, this.state.selectedResource.service.group_id);

        this.setState({ isCheckingCollision: true });

        // Ignore Collisions for Walkup Services being edited by a manager
        if(this.state.selectedResource.service.type == service_types.WALKUP && isManager) {
            let new_state = state_merge; 
            this.setState({...new_state, isCheckingCollision: false});
            return;  
        }

        // Otherwise Check Collisions
        let test_event = {
            service_id: this.state.selectedResource.id,
            start_date: state_merge.start_date,
            end_date: moment(state_merge.start_date).add(state_merge.duration, "minutes"),
            rate_id: this.state.activeRate.id
        }
        if (this.state.mode == "create") { test_event.id = "new"; }
        if (this.state.mode == "edit") { test_event.id = this.props.clickParams.eventData.id; }
        return EventData.checkCollision(test_event).then(( collisions ) => {

            let new_state = state_merge;

            new_state.error = false;
            new_state.isValid = true;
            
            new_state.collisions = collisions.collisions;

            if (collisions.collisions.length > 0) {
                if (collisions.interevent_gap_duration > 0) { new_state.error = { message: `Warning: This service has an interevent gap duration of ${collisions.interevent_gap_duration} minutes. Please ensure that all events have at least ${collisions.interevent_gap_duration} minutes between them.`}}
                new_state.isValid = false;
            }

            if (!EventData.allowAfterHours(this.state.selectedResource.service) && collisions.durations.off > 0) {
                new_state.error = { message: "After hours scheduling is not allowed on this service" };
                new_state.isValid = false;
            }

            if(collisions.rel_collisions.length > 0) { 
                new_state.error = { message: `Warning: The selected process or rate [${this.state.activeRate.label}] is part of a rate exclusion group. The following events are creating a rate exclusion collision: [ ${collisions.rel_collisions.map(c => `Event ${c?.id} owned by ${c?.order?.user?.full_name} on service ${c?.service?.name}`).join(", ")} ]. Please select a different rate OR contact the facility to resolve the scheduling conflict.`}
                new_state.isValid = false;
            }

            return new Promise((resolve) => {
                this.setState({ ...new_state, isCheckingCollision: false }, () => { resolve(); });
            });

            
            
        }).catch((err) => {
            console.log(err)
            this.setState({ error: err, isCheckingCollision: false });
        })
    }

    updateResource(event) {
        let selectedResource = this.props.resources.reduce((a, r) => {
            if (r.id == event.currentTarget.value) { return r; }
            return a;
        }, this.props.resources[0])
        this.setState({ selectedResource: selectedResource });
    }

    incrementStart() {
        let new_start = moment(this.state.start_date).add(this.state.selectedResource.duration, "minute");
        this.checkCollision({ start_date: new_start, duration: this.state.duration })
    }

    decrementStart() {
        let new_start = moment(this.state.start_date).subtract(this.state.selectedResource.duration, "minute");
        this.checkCollision({ start_date: new_start, duration: this.state.duration })
    }

    incrementDuration() {
        let new_duration = this.state.duration + this.state.selectedResource.duration;
        if (new_duration <= this.state.selectedResource.duration) { new_duration = this.state.selectedResource.duration; }
        this.checkCollision({ start_date: this.state.start_date, duration: new_duration })
    }

    decrementDuration() {
        let new_duration = this.state.duration - this.state.selectedResource.duration;
        if (new_duration <= this.state.selectedResource.duration) { new_duration = this.state.selectedResource.duration; }
        this.checkCollision({ start_date: this.state.start_date, duration: new_duration })
    }

    setDuration(new_duration) {
        this.checkCollision({ start_date: this.state.start_date, duration: new_duration })
    }

    startDateOnWheel(evt) {
        evt.preventDefault()
        if (evt.deltaY < 0) {
            this.decrementStart()
        }
        if (evt.deltaY > 0) {
            this.incrementStart()
        }
    }

    durationOnWheel(evt) {
        evt.preventDefault()
        if (evt.deltaY < 0) {
            this.decrementDuration()
        }
        if (evt.deltaY > 0) {
            this.incrementDuration()
        }
    }

    submitEvent() {
        if(typeof this.props.onSubmit == "function") {
            if(this.state.mode == 'create') {
                this.props.onSubmit({
                    id: "new",
                    service_id: this.state.selectedResource.id,
                    start_date: this.state.start_date,
                    duration: this.state.duration,
                    end_date: moment(this.state.start_date).add(this.state.duration, "minutes"),
                    user_id: this.state.selectedUser.id,
                    account_id: this.state.selectedAccount.id,
                    rate_id: this.state.activeRate.id,
                    po_number: this.state.po_number,
                    description: this.state.description,
                    requested_notifications: this.state.notifications,
                    data_1: this.state.data_1,
                    data_2: this.state.data_2,
                    data_3: this.state.data_3,
                    data_4: this.state.data_4,
                    data_5: this.state.data_5
                });
            }

            if(this.state.mode == "edit") {
                let updateData = _.clone(this.props.clickParams.eventData);
                updateData.id = this.props.clickParams.eventData.id;
                updateData.service_id = this.state.selectedResource.id;
                updateData.start_date = this.state.start_date;
                updateData.duration = this.state.duration;
                updateData.end_date = moment(this.state.start_date).add(this.state.duration, "minutes");
                updateData.order.owner_id = this.state.selectedUser.id;
                updateData.order.user = this.state.selectedUser;
                updateData.order.account_id = this.state.selectedAccount.id;
                updateData.order.account = this.state.selectedAccount;
                updateData.rate_id = this.state.activeRate.id;
                updateData.rate = this.state.activeRate;
                updateData.order.po_number = this.state.po_number;
                updateData.description = this.state.description;
                updateData.order.billing_exclusion_justification = this.state.billing_exclusion_justification || null;
                updateData.data_1 = this.state.data_1;
                updateData.data_2 = this.state.data_2;
                updateData.data_3 = this.state.data_3;
                updateData.data_4 = this.state.data_4;
                updateData.data_5 = this.state.data_5;
                updateData.order.order_billing_status = this.state.order_billing_status;
                this.props.onSubmit(updateData);
            }
        }
    }

    cancelEvent() {
        if (typeof this.props.onCancel == "function") {
            this.props.onCancel();
        }
    }

    deleteEvent() {
        if (typeof this.props.onDelete == "function") {
            this.props.onDelete(this.props.clickParams.eventData);
        }
    }

    updateRate(event) {
        let selectedRate = this.props.clickParams.serviceRates.reduce((a, r) => {
            if (event.currentTarget.value == r.id) { return r; }
            return a;
        }, this.state.selectedRate);
        this.setState({ activeRate: selectedRate }, () => {
            this.checkCollision({ start_date: this.state.start_date, duration: this.state.duration })
        });
    }

    updateStatus(event) {
        this.setState({ order_billing_status: event.currentTarget.value });
    }

    updatePONumber(event) {
        this.setState({ po_number: event.currentTarget.value });
    }

    updateDescription(event) {
        this.setState({ description: event.currentTarget.value });
    }

    requestNotifcation(offset) {
        let pendingNotifications = this.state.notifications;
        pendingNotifications.push(`offset::${offset}`);
        this.setState({ notifications: pendingNotifications });
    }

    removeNotificationRequest(index) {
        let pendingNotifications = this.state.notifications;
        pendingNotifications.splice(index, 1);
        this.setState({ notifications: pendingNotifications });
    }

    postUnlock() {
        let service = this.state.selectedResource.service
        let service_tags = service?.tags?.split(",") || [];
        alert("Unlock Command Sent")
    }

    postLock() {
        let service = this.state.selectedResource.service
        let service_tags = service?.tags?.split(",") || [];
        let shouldUpdateEnd = false;
        let now = moment();
        let end_date = moment(this.state.start_date).add(this.state.duration, "minutes")
        let time_adjust_mode = "trim";
        let hasPostLock = service_tags.reduce((postLock, tag) => {
            if(tag == "trim_end_on_lock" && end_date.isAfter(now)) { shouldUpdateEnd = true; time_adjust_mode = "trim";  }
            if(tag == "set_end_on_lock") { shouldUpdateEnd = true; time_adjust_mode = "set"; }
            if(tag.includes("post_lock:")) { 
                let components = tag.split(":")
                components.splice(0, 1);
                return components.join(":");
            }
            return postLock;
        }, false);

        let postLockAction = (shouldCancel) => {
            if(shouldCancel) { this.cancelEvent(); }
            window.open(hasPostLock, "_blank");
            // SiteDialogData.open("Post Use Data Request", <div> 
            //     <div style={{ paddingBottom: 10 }}>Please go to the following link to fill out the post-use data collection form: </div>
            //     <AnchorButton icon="document-open" intent="primary" href={hasPostLock} target="_blank"> {hasPostLock} </AnchorButton>
            // </div>)
        }

        alert("Lock Command Sent")

        if(shouldUpdateEnd) { 
            // let newDuration = now.diff(moment(this.state.start_date), "minutes")
            this.setState({ duration: time_adjust_mode }, this.submitEvent); 
            if(hasPostLock !== false) { postLockAction(false); }
        } else {
            if(hasPostLock !== false) { postLockAction(true); }
        }
        
    }

    render() {
        const self = this;


        let resourceList = [];
        if(self.state.mode == "create") {
            resourceList = this.props.resources.map((resource) => {
                return { label: resource.title, value: resource.id };
            })
        }
        
        if(this.state.mode == "edit") {
            resourceList = [{ label: self.props.clickParams.eventData.service.name, value: self.props.clickParams.eventData.service.id }]
        }
        

        let formattedDuration = `${this.state.duration} Minutes`;
        if(Math.floor(this.state.duration/60) > 0) { 
            if (Math.floor(this.state.duration % 60) == 0) {
                formattedDuration = `${Math.floor(this.state.duration / 60)} Hours`;
            } else {
                formattedDuration = `${Math.floor(this.state.duration / 60)} Hours ${Math.floor(this.state.duration % 60)} Minutes`;
            }
            
        }

        let isManager = AppData.authorize(20, this.state.selectedResource.service.group_id);

        let ratesList = this.props.clickParams.serviceRates.map((rate) => {
            let labelString = rate.label
            let disabled = false;
            if (`${rate.tags || ""}`.includes("require:group:manager") && !isManager) { disabled = true; labelString += " -- Manager Only -- " }
            return { label: labelString, value: rate.id, disabled: disabled };
        });

        if (ratesList.length == 0) { 
            return <div>
                <Callout intent="danger" title="Service Configuration Error"> Warning: This services has no charge rates configured. Please let the group manager know that this service is not usable until at least one rate has been configured. </Callout>
            </div>
        }


        // Configuration for the user/account selector component
        let userAccountConfig = {
            allow_user_select: isManager,
            allow_account_select: true
        }

        let userAccountHandlers = {
            onUserSelected: (user, isValid) => {
                this.setState({
                    selectedUser: user,
                    selectedAccount: null
                });

            },
            onAccountSelected: (account, isValid) => {
                this.setState({
                    selectedAccount: account
                });
            }
        }
        
        let errors = []
        let collisions = <span></span>;
        let isValid = this.state.isValid;
        let allowDurationEdit = true;

        if(this.state.error !== false) {
            errors.push(<div key="generic_error"> {this.state.error.message || JSON.stringify(this.state.error)}</div>)
            isValid = false;
        }

        let collisionsList = _.clone(this.state.collisions);

        if (collisionsList.length > 0) {
            collisions = collisionsList.map((collision, index) => {
                return (<div key={`collision_${index}`}> This event collides with order <span>{collision.order.id}</span> (Event <span>{collision.id}</span>) which runs from <span>{moment(collision.start_date).tz(AppData.timezone()).format("YYYY-MM-DD HH:mm:ss")}</span> to <span>{moment(collision.end_date).tz(AppData.timezone()).format("YYYY-MM-DD HH:mm:ss")}</span></div>)
            })
            isValid = false;
        }

        let selectedService = {};
        if (this.state.selectedResource.service) {  selectedService = this.state.selectedResource.service; }
        
        // No Account Selected
        if(this.state.selectedAccount == null) { isValid = false; }

        // Time Violation Reference Variables
        const is_in_past = moment(this.state.start_date).isBefore(moment())
        let minimumLeadSchedulingViolation = false;

        let service_lead_time = parseFloat(this.state.selectedResource.service.lead_time);
        let lead_end_time = moment().add(service_lead_time * (24 * 60), 'minutes'); // Added Via Minute vs day because fractional days are not supported by moment.js
        const is_in_lead_time = moment(this.state.start_date).diff(lead_end_time, "days", true) < 0;
        const is_beyond_max_lead_time = moment(this.state.start_date).add(this.state.duration, "minutes").isAfter(moment().add(this.state.selectedResource.service.maximum_lead_time, 'day'));

        // Minimum Lead Time Violation Check
        if (is_in_lead_time && this.state.mode == "create") { 
            let allowSchedulInLeadTime = false;
            if (this.state.selectedResource.service.allow_lead_time_scheduling == 1) { allowSchedulInLeadTime = true; }
            if ((this.state.selectedResource?.service?.tags || "").includes("after_hours_users_ignore_lead_time")) { allowSchedulInLeadTime = true; }
            if (this.state.allowUserScheduleInLeadTime) { allowSchedulInLeadTime = true; }
            if(!allowSchedulInLeadTime) { minimumLeadSchedulingViolation = true; }
        }
        if (is_in_lead_time && this.state.mode == "edit") { minimumLeadSchedulingViolation = true; }

        // Max Lead Time Violation Check
        let exempt_from_max = false;
        if (this.state.selectedUser.tags == "string" && RegExp(`ignore_service_max_lead_time:${this.state.selectedResource.service.id}`).test(this.state.selectedUser.tags)) { exempt_from_max = true; }
        if ((this.state.selectedAccount !== null && typeof this.state.selectedAccount.tags == "string") && RegExp(`ignore_service_max_lead_time:${this.state.selectedResource.service.id}`).test(this.state.selectedAccount.tags)) { exempt_from_max = true; }

        // Past Event Violation Check
        if (!isManager && is_in_past) {
            isValid = false;
            allowDurationEdit = false;
            errors.push(<div key="past_event_error"> Cannot schedule or edit events in the past. Please contact the core to schedule or update events in the past. </div>)
        }

        // Min Lead Time Violation Check
        if (!isManager && minimumLeadSchedulingViolation && !is_in_past ) {
            isValid = false;
            errors.push(<div key="leadtime_violation_error"> {(this.state.mode == "create" ? "Creating an event" : "Updating a created event")} inside the service lead time is not allowed. This service has a lead time of {this.state.selectedResource.service.lead_time} days. Please contact the core to schedule or update events inside the service lead time. </div>)
        }

        // Max Lead Time Violation Check
        if (!isManager && !exempt_from_max && this.state.selectedResource.service.maximum_lead_time > 0 && is_beyond_max_lead_time) {
            isValid = false;
            errors.push(<div key="leadtime_violation_error"> {(this.state.mode == "create" ? "Creating an event" : "Updating a created event")} beyond the service maximum lead time is not allowed. This service has a maximum lead time of {this.state.selectedResource.service.maximum_lead_time} days. Please contact the core to schedule or update events inside the service lead time. </div>)
        }

        if(!areRequiredDataFieldsValid(selectedService, this.state)) { 
            isValid = false;
        }

        let actions = [
            <Button key="action_submit" text={`${(self.state.mode == "edit" ? "Update" : "Create")} Event`} intent="success" disabled={(!isValid || this.state.isCheckingCollision)} onClick={self.submitEvent} />,
            <Button key="action_close" text={`${(self.state.mode == "edit" ? "Close" : "Cancel")}`} intent="warning" onClick={self.cancelEvent} />
        ]

        let allowDelete = false;
        if(!isManager && !is_in_lead_time && !is_in_past) { allowDelete = true; }
        if(isManager) { allowDelete = true; }

        if (self.state.mode == "edit" && allowDelete) { actions.push(<Button key="action_delete" text={"Delete"} intent="danger" onClick={self.deleteEvent} /> ) };
        
        // Disable Updating of Billed Orders
        if (self.props.clickParams.eventData && self.props.clickParams.eventData.order.order_billing_status >= 3) {
            allowDurationEdit = false;
            actions = [
                <Button key="action_none" text={`Event Billed`} intent="success" disabled={true} />,
                <Button key="action_close" text={`Close Event`} intent="warning" onClick={self.cancelEvent} />
            ]
        }

        let billing_status_control = (<div>
            <FormGroup style={{ marginTop: 10 }}
                label="Billing Status"
                labelInfo="(required)">
                <HTMLSelect options={[
                    { label: "Excluded", value: 0, disabled: (this.state.order_billing_status >= 3) },
                    { label: "Pending", value: 1, disabled: (this.state.order_billing_status >= 3) },
                    { label: "Approved", value: 2, disabled: (this.state.order_billing_status >= 3) },
                    { label: "Billed", value: 3, disabled: true }
                ]} value={this.state.order_billing_status} readOnly={isManager} onChange={this.updateStatus} />
            </FormGroup>
            {( this.state.order_billing_status == 0 ? <FormGroup style={{ marginTop: 10 }}
                label="Exclusion Justification"
                labelInfo="(required)"
                intent={(`${this.state.billing_exclusion_justification}`.length < 1 ? "warning" : "none")}>
                <InputGroup type="text" value={this.state.billing_exclusion_justification || ""} onChange={(e) => {
                    this.setState({ billing_exclusion_justification: e.currentTarget.value });
                }}/>
            </FormGroup> : "")}
        </div>)
        if(self.state.mode == "create") { billing_status_control = <div></div>;  }

        // Show Service Messages
        let serviceMessages = "";
        if (this.state.selectedResource.service.service_notes !== null) { 
            serviceMessages = this.state.selectedResource.service.service_notes.trim() 
        }
        var messagesHTML = markdown.render(serviceMessages);
        const service_tags = (this.state?.selectedResource?.service.tags || "").split(",");
        var interlockForwardGracePeriod = 5;
        var interlockPostGracePeriod = 30;
        const hasInterlock = service_tags.reduce((hi, t) => { 
            if (`${t}`.startsWith("has_interlock")) {
                var tag_components = t.split(":")
                if(tag_components.length == 3) {
                    var forward = parseInt(tag_components[1], 10)
                    var post = parseInt(tag_components[2], 10) 
                    interlockForwardGracePeriod = (!isNaN(forward) ? forward : 5)
                    interlockPostGracePeriod = (!isNaN(post) ? post : 5)
                }
                if(tag_components.length == 2) {
                    var forward = parseInt(tag_components[1], 10)
                    interlockForwardGracePeriod = (!isNaN(forward) ? forward : 5)
                    interlockPostGracePeriod = 1440
                }
                return true;
            }
            return hi;
        }, false)
        const now = moment()
        const eventStarted = now.isAfter(moment(this.state.start_date).subtract(interlockForwardGracePeriod,"minutes")) // 5 Minute Grace Period for Interlock Control
        const eventEndDate = moment(this.state.start_date).add(this.state.duration, "minutes")
        const eventEnded = now.isAfter(moment(eventEndDate).add(interlockPostGracePeriod, "minutes")) // 30 Minute Grace Period for Interlock Control
        const currentUserIsOwner = AppData.get("userID") == this.state.selectedUser.id;
        const allowInterlock = (isManager || (currentUserIsOwner && !eventEnded && eventStarted)) && (this.state.mode == "edit");
        var showInterlockControls = (hasInterlock && allowInterlock);

        return (<div>
            <div className="crs event-detail actions">
                {(this.props.actionInProgress !== true ? actions : <ProgressBar/>)}
            </div>
            <div>
                <div className="crs event-detail service-notes"><div dangerouslySetInnerHTML={{ __html: messagesHTML }}></div></div>
                <div className="crs event-detail error">{errors}</div>
                <div className="crs event-detail collisions">{collisions}</div>
                
                <FormGroup
                    label="Resource"
                    labelInfo="(required)">
                    <HTMLSelect options={resourceList} value={this.state.selectedResource.id} onChange={this.updateResource}/>
                </FormGroup>
                <FormGroup
                    label="Event Start Time"
                    labelInfo={<span> (required) </span>}
                    helperText={( this.state.selectedResource.service.type == service_types.WALKUP ? "Walkup Event Start Times Cannot Be Edited" : "May use mouse wheel to adjust. Click for date picker." )}>
                        { this.state.selectedResource.service.type == service_types.WALKUP ? (<ControlGroup>
                            <InputGroup value={moment(this.state.start_date).tz(AppData.timezone()).format("YYYY-MM-DD HH:mm")} readOnly={!allowDurationEdit} />
                            </ControlGroup>) : (
                            <ControlGroup>
                                <Button icon={"less-than"} onClick={this.decrementStart} readOnly={!allowDurationEdit}></Button>
                                <DateInput shortcuts={true} 
                                    timePrecision={(this.state.selectedResource.duration == 1 ? "minute" : undefined)}
                                    showTimezoneSelect={false} value={moment(this.state.start_date).toDate().toISOString()}
                                    formatDate={(d) => {
                                        if(d == null) { return moment(self.state.revertDate).tz(AppData.timezone()).format("YYYY-MM-DD HH:mm") }
                                        return moment(d).tz(AppData.timezone()).format("YYYY-MM-DD HH:mm");
                                    }}
                                    parseDate={(s) => { 
                                        if(`${s}`.trim() == "") { return moment(self.state.revertDate).tz("UTC").toDate() }
                                        return moment(s, "YYYY-MM-DD HH:mm").tz("UTC").toDate() 
                                    }}
                                    maxDate={moment().add(2, "years").toDate()}
                                    onChange={(new_date) => {
                                        if(this.state.selectedResource.duration == 1) {
                                            let new_start = moment(new_date);
                                            this.checkCollision({ start_date: new_start })
                                            this.setState({ start_date: new_start });
                                        } else {
                                            let start_date = moment(this.state.start_date)
                                            let new_start = moment(new_date).hours(start_date.hours()).minutes(start_date.minutes()).seconds(start_date.seconds());
                                            this.checkCollision({ start_date: new_start })
                                            this.setState({ start_date: new_start });
                                        }
                                    }} inputProps={{"onWheel":this.startDateOnWheel}}/>
                                <Button icon={"greater-than"} onClick={this.incrementStart} readOnly={!allowDurationEdit}></Button> 
                                <Tooltip content={<span>Created: {moment(self.state.createdAt).format("YYYY-MM-DD HH:mm")}</span>}><Button icon="info-sign" /></Tooltip>
                            </ControlGroup>
                        )}
                </FormGroup>
                <FormGroup
                    label="Event Duration"
                    labelInfo="(required)"
                    helperText="Click Hour/Minute Field (Click on Number, Tag Turns Green) and use arrow keys for large/repeating changes.">
                    <DurationField value={this.state.duration} minUnitBlock={this.state.selectedResource.duration} onChange={this.setDuration} readOnly={!allowDurationEdit} />
                </FormGroup>
                <FormGroup 
                    label="Event Ends">
                    <ControlGroup>
                        <Button icon={"less-than"} onClick={this.decrementDuration} disabled={!allowDurationEdit}></Button>
                        <InputGroup value={moment(this.state.start_date).tz(AppData.timezone()).add(this.state.duration, "minutes").format("YYYY-MM-DD HH:mm")} readOnly={true} />
                        <Button icon={"greater-than"} onClick={this.incrementDuration} disabled={!allowDurationEdit}></Button>
                    </ControlGroup>
                </FormGroup>
                <UserAccountPicker user={this.state.selectedUser} account={this.state.selectedAccount} handlers={userAccountHandlers} config={userAccountConfig} comparisonDate={moment(this.state.start_date)}/>
                {(self.state.mode == "create" ? <RequestedEventUserNotifications requestedNotifications={this.state.notifications} onCreate={this.requestNotifcation} onRemove={this.removeNotificationRequest}/> : <EventUserNotifications event={{
                    id: self.props.clickParams.eventData.id,
                    start_date: moment(this.state.start_date),
                    service: this.state.selectedResource.service
                }}/> )}
                {(showInterlockControls ? <InterlockControls service={this.state.selectedResource} postLock={this.postLock} postUnlock={this.postUnlock}/> : <div></div>)}
                <FormGroup
                    label="Process"
                    labelInfo="(required)">
                    <HTMLSelect options={ratesList} value={this.state.activeRate.id} onChange={this.updateRate} />
                </FormGroup>
                <FormGroup style={{ marginTop: 10 }}
                    label="PO Number"
                    labelInfo="(optional)">
                    <InputGroup value={this.state.po_number || ""}  onChange={this.updatePONumber} />
                </FormGroup>
                <FormGroup style={{ marginTop: 10 }}
                    label="Description/Comments"
                    labelInfo="(optional)">
                    <TextArea value={this.state.description || ""} onChange={this.updateDescription} />
                </FormGroup>
                { (ServiceData.dataIsActive(selectedService, 1) ? <DataInputField service={selectedService} fieldIndex={1} value={this.state.data_1 || ""} onChange={(evt) => { self.setState({ data_1: evt.currentTarget.value }); }}/> : <span></span>) }
                { (ServiceData.dataIsActive(selectedService, 2) ? <DataInputField service={selectedService} fieldIndex={2} value={this.state.data_2 || ""} onChange={(evt) => { self.setState({ data_2: evt.currentTarget.value }); }}/> : <span></span>) }
                { (ServiceData.dataIsActive(selectedService, 3) ? <DataInputField service={selectedService} fieldIndex={3} value={this.state.data_3 || ""} onChange={(evt) => { self.setState({ data_3: evt.currentTarget.value }); }}/> : <span></span>) }
                { (ServiceData.dataIsActive(selectedService, 4) ? <DataInputField service={selectedService} fieldIndex={4} value={this.state.data_4 || ""} onChange={(evt) => { self.setState({ data_4: evt.currentTarget.value }); }}/> : <span></span>) }
                { (ServiceData.dataIsActive(selectedService, 5) ? <DataInputField service={selectedService} fieldIndex={5} value={this.state.data_5 || ""} onChange={(evt) => { self.setState({ data_5: evt.currentTarget.value }); }}/> : <span></span>) }
            </div>
            {((isManager && this.state.mode == "edit" && [0,1].includes(this.state.order_billing_status)) ? <div style={{ overflow: "auto", width: "100%" }}><OrderLineItemManager order_id={this.props.clickParams.eventData.order_id} service_id={this.props.clickParams.eventData.service_id} preset={[]} event_duration={this.state.duration/60}/></div> : "")}
            {(isManager ? billing_status_control : "")}
            <div className="crs event-detail actions">
                {(this.props.actionInProgress !== true ? actions : <ProgressBar/>)}
            </div>
            
        </div>)
    }
}